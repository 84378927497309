<template>
  <div>
    <NavBar class="mb-2"></NavBar>
    <b-container>
      <h3>Foreword</h3>
      <hr>
      <p>This book is about Go.</p>
      <p>My main objective is to teach you the language in a progressive way. I also tried to clarify and explain some
        common computer science notions that can be difficult to grasp, especially for newcomers.</p>
      <p>I started to write it in 2018 during weekends and nights. At the end of 2020, I decided to quit my job to work
        on it full-time. Those 2.5 years of writing were intense but rewarding.</p>
      <p>I share this work for free on this website. For years I learned so many things from others on the internet.
        Today, I want to give back to my community!</p>
      <p>I want to thank my family for its support, especially my spouse, who brings me joy and happiness every day.</p>
      <p><strong>Maximilien Andile</strong>, 12 March 2021</p>

      <!-- Next / Previous -->
      <b-row class="ml-1 mr-1 ">

        <b-col class="text-center border mr-1 p-2" >
          <router-link :to="{name:'Chap1ProgrammingAComputer'}">
            <p><u><small>First Chapter</small></u></p>
            <p><small>Programming A Computer</small></p>
          </router-link>
        </b-col>
      </b-row>
      <b-row class="mt-1 ml-1 mr-1">
        <b-col class="text-center border p-1 ">
          <b-link :to="{name:'Home'}" >Table of contents</b-link>
        </b-col>
      </b-row>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
const title = "Foreword - Practical Go Lessons"
const description = "Foreword to the book"
export default {
  name: "Foreword",
  components: {Footer, NavBar},
  metaInfo: {
    title: title,
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: description },
      { name: 'robots', content: "index, follow" },
      { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
      { property: 'og:type', content: "website" },
      { property: 'og:title', content: title},
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:site_name', content: 'Practical Go Lessons' },
      { property: 'twitter:card', content: "summary_large_image" },
      { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
    ],
    link : [
      { rel : "canonical", href : window.location.href}
    ]

  },
  created() {
    window.scrollTo(0,0);
  }
}
</script>

<style scoped>

</style>